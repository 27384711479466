<template>
  <v-app-bar class="px-3"
             color="white"
             flat
             density="compact" v-if="isAuthenticated">
    <v-container class="d-flex align-center justify-sm-space-between header-container">
      <router-link :to="{name:'delivery.list'}" class="nav-logo-wrap">
      <img class="nav-logo" src="logo.png">
      </router-link>
      <span style="margin-left: auto">

        <template v-if="authUser.type=='owner'">
          <router-link :to="{name:'report.payment.list'}">
            <v-chip class="user-balance me-1" prepend-icon="mdi-currency-usd" variant="text" text-color="success">{{Math.ceil(authUser.balance)}} грн.</v-chip>
          </router-link>
          <router-link :to="{name:'report.fuel.list'}">
            <v-chip class="fuel-list me-1 text-center" prepend-icon="mdi-gas-station" variant="text" color="#009688"></v-chip>
          </router-link>
          <router-link :to="{name:'report.salary.list'}">
            <v-chip class="user-balance me-1" prepend-icon="mdi-wallet-outline" variant="text" text-color="success"></v-chip>
          </router-link>
        </template>
        <span class="ms-3 user-name">{{ authUser.name }}</span>
        <span @click="logout" class="mdi mdi-logout ms-1"></span>
      </span>

    </v-container>
  </v-app-bar>
</template>

<script>
import {mapGetters} from "vuex";
//import SwitchLocale from "@/components/layouts/SwitchLocale";
import axios from "axios";
import store from "@/store";
export default {
  name: "UppMenuCentral",
  //components: {SwitchLocale},
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated',
      authUser: 'authUser',
      isTypeUser: 'isTypeUser',
      isVerification: 'isVerification'
    }),
  },
  data() {
    return {
      status_location:false
    };
  },
  mounted() {
    if(this.authUser.type=='hired'){
      this.getUserLocation()
    }
  },
  methods: {
    async logout() {
      axios.post('/api/auth/logout').then(r => {
        if (r.status) {
          store.commit('auth/isAuthenticated', false)
          store.commit('auth/authUser', {})
          localStorage.removeItem('X-XSRF-TOKEN')
          this.$router.push({name: 'central.login'})
        }
      })
    },
    async getUserLocation() {
      try {
        const position = await this.getPosition()
        this.updateUserLocation(position.coords.latitude, position.coords.longitude)
        setInterval(async () => {
          const position = await this.getPosition()
          this.updateUserLocation(position.coords.latitude, position.coords.longitude)
        }, 20000)
        this.status_location = true;
      } catch (err) {
        //console.error(err)
        this.status_location = false;
      }
    },
    getPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
    },
    updateUserLocation(latitude, longitude) {
      if(this.status_location){
        axios.post('/api/driver/set/location',{ latitude:latitude, longitude:longitude })
      }
    }
  },
}
</script>

<style scoped>

</style>
